@media screen and (min-width: 992px) {
    .video{
        width: 70%;
        float: left;
    }
    .video iframe{
        height: 70vh;
    }
    .video-parts{
        width: 30%;
        float: left;
        /* margin-top: 10%; */
    }
    
}
.video iframe{
    width: 100%
}
main{
    clear: both;
    width: 100%;
}
