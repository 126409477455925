h1{
  width: 100vw;
  text-align: center;
}

.big-card h2{
  width: 100vw;
  text-align: center;
}
h2 a{
  color: rgb(175,184,193);
  margin: 0 10px;
  cursor: pointer;
}
/* h2:hover{
  color: #646cff;
} */

.card{
  text-align: center;
}

ul{
  list-style: none;
  padding: 0;
  margin: 0;
}